<template>
    <div :class="[flag? '': 'content-box']">
        <div :class="[flag? '': 'container']">
            <div class="content">
                <div class="content_row flex">
                    <label class="info" for="">姓名</label>
                    <div class="content_right">
                        {{data.name}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">性别</label>
                    <div class="content_right">
                        <!-- <a-select v-model="data.sex" class=" ant-select-lg">
                        <a-select-option :value="1">男</a-select-option>
                        <a-select-option :value="2">女</a-select-option>
                    </a-select> -->
                        {{data.sex==''?'':data.sex==1?'男':'女'}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">出生日期</label>
                    <div class="content_right">
                        {{data.age}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">身份证号</label>
                    <div class="content_right">
                        {{data.code}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">身份证正面</label>
                    <div class="content_right">
                        <img style="width:200px;display: block;" v-if="data.idcardfront" :src="ajaxUrl+'/scrm/index/getFile?id='+data.idcardfront">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">身份证背面</label>
                    <div class="content_right">
                            <img style="width:200px;display: block;" v-if="data.idcardback" :src="ajaxUrl+'/scrm/index/getFile?id='+data.idcardback">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">电话</label>
                    <div class="content_right">
                        {{data.tel}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">基数</label>
                    <div class="content_right">
                        {{data.base}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">所属部门</label>
                    <div class="content_right">
                        {{data.departmentname}}
                    </div>
                </div>
                <!-- <div class="content_row flex">
                <label class="info" for="">职务</label>
                  <div class="content_right">
                  {{data.post}}
                </div>
            </div> -->
                <div class="content_row flex">
                    <label class="info" for="">银行卡号</label>
                    <div class="content_right">
                        {{data.card}}
                    </div>
                </div>

                <div class="content_row flex">
                    <label class="info" for="">紧急联系人</label>
                    <div class="content_right">
                        {{data.contacts}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">紧急联系人电话</label>
                    <div class="content_right">
                        {{data.contactstel}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">状态</label>
                    <div class="content_right">
                        {{data.status==''?'':data.status==1?'在职':'离职'}}
                    </div>
                </div>
                <div class="content_row flex" v-if="data.status==2">
                    <label class="info" for="">离职时间</label>
                    <div class="content_right">
                        {{data.endtime}}
                    </div>
                </div>
                <!-- <div class="content_row flex">
                <label class="info" for="">跟进人</label>
                  <div class="content_right">
                       <span v-for="(item,index) in staff" :key="index" > {{data.uid==item.userid?item.name:''}}</span> -->

                <!-- <a-select v-model="data.uid" disabled  class="ant-select-lg">
                        <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">{{item.name}}</a-select-option>
                    </a-select> -->
                <!-- </div>
            </div> -->
                <div class="content_row flex">
                    <label class="info" for="">工作点</label>
                    <div class="content_right">
                        <span v-for="(item,index) in operatList" :key="index"> {{data.opid==item.id?item.oname:''}}</span>

                        <!-- <a-select v-model="data.opid" disabled class="ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in operatList" :key="index">{{item.oname}}</a-select-option>
                    </a-select> -->
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">是否有保洁证</label>
                    <div class="content_right">
                        {{data.cleaning==2?'有':'无'}}
                        <!-- 
                    <a-select v-model="data.cleaning" class="ant-select-lg">
                        <a-select-option :value="2">有</a-select-option>
                        <a-select-option :value="1">无</a-select-option>
                    </a-select> -->
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">是否有绿化证</label>
                    <div class="content_right">
                        {{data.green==2?'有':'无'}}
                        <!-- <a-select v-model="data.green" class="ant-select-lg">
                        <a-select-option :value="2">有</a-select-option>
                        <a-select-option :value="1">无</a-select-option>
                    </a-select> -->
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">入职时间</label>
                    <div class="content_right">
                        <!-- <a-date-picker v-model="createtime"  size="large" @change="onPickCTime" /> -->
                        {{createtime}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">备注</label>
                    <div class="content_right">
                        {{data.remarks}}
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">投保记录</label>
                    <div class="content_right">
                        <div style="width:80%;text-align:center">
                            <a-button type="primary" class="editable-add-btn" @click="showInsure()" style="margin-bottom:10px">新增投保</a-button>
                            <a-table :columns="insureColumns" :data-source="insureList" class="table" :bordered="true" :pagination="false">
                                <template slot="Action" slot-scope="text,record">
                                    <a class="action del" href="javascript:;" @click="delInsure(record.id)">删除</a>
                                </template>
                            </a-table>
                        </div>

                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">工服发放记录</label>
                    <div class="content_right">
                        <div style="width:80%;text-align:center">
                            <a-button type="primary" class="editable-add-btn" @click="showClothes()" style="margin-bottom:10px">工服发放</a-button>
                            <a-table :columns="clothesColumns" :data-source="clothesList" class="table" :bordered="true" :pagination="false">
                                <template slot="Action" slot-scope="text,record">
                                    <a class="action del" href="javascript:;" @click="delClothes(record.id)">删除</a>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">工服补助发放记录</label>
                    <div class="content_right">
                        <div style="width:80%;text-align:center">
                            <a-table :columns="subsidyColumns" :data-source="subsidyList" class="table" :bordered="true" :pagination="false">
                            </a-table>
                        </div>
                    </div>
                </div>
                <a-modal v-model="insureModal" :centered="true" title="投保" @ok="setInsure">
                    <div class="addBox">
                        <div class="add_title">保险类型</div>
                        <a-select v-model="insData.insurance" @change="insuranceChange" style="width:100%" class="ant-select-lg">
                            <a-select-option :value="1">工伤保险</a-select-option>
                            <a-select-option :value="2">商业保险</a-select-option>
                            <a-select-option :value="3">社会保险</a-select-option>

                        </a-select>
                        <div class="add_title">投保金额</div>
                        <a-input v-model="insData.money" size="large" type="number" placeholder="请输入投保金额" />
                        <template>
                            <div class="add_title">保险生效时间</div>
                            <a-date-picker size="large" v-model="insData.createtime"/>
                            <div class="add_title">保险到期时间</div>
                            <a-date-picker size="large" v-model="insData.endtime" />
                        </template>
                    </div>
                </a-modal>
                <a-modal v-model="clothesModal" :centered="true" title="发放工服" @ok="setClothes">
                    <div class="addBox" style="padding-bottom:45px">
                        <div class="add_title">工服类型</div>
                        <a-select v-model="onClotype" style="width:100%;" class="ant-select-lg">
                            <a-select-option :value="item.id" v-for="(item,index) in clotypeList" :key="index">{{item.name}}</a-select-option>
                        </a-select>
                    </div>
                </a-modal>
                <!-- <div class="save"><a-button type="primary" @click="submit">保存</a-button></div> -->
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import { isMobile, ajaxUrl, requestXml, getDateTime } from '../../../assets/js/request';
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            id: "",
            ajaxUrl: ajaxUrl,
            staff: '', //员工列表
            skillid: '', //技能列表
            data: {
                "name": "",
                "number": "",
                "sex": "",
                "age": "",
                "code": "",
                "tel": "",
                "status": "",
                "post": "",
                "card": "",
                "contacts": "",
                "contactstel": "",
                "uid": "",
                "remarks": "",
                "base": "",
                "cleaning": '',
                "green": '',
                "createtime": '',
            },
            createtime: '', //入职时间
            selSkillid: '',  //技能选项
            operatList: '', //工作点列表
            insureModal: false, //新增投保窗口
            insData: {
                "workerid": '',
                "insurance": '',
                "money": "",
                "uid": "",
                "time": "",
                "createtime": "",
                "endtime": "",
            },
            insureList: '', //投保信息
            insCTime: '', //保险生效时间
            insETime: '', //保险到期时间
            clothesList: '', //工装记录
            clotypeList: '', //工服类型列表
            onClotype: '', //选中工服类型
            clothesModal: false, //添加工服窗口
            subsidyList: '',  //补助记录 
            insureColumns: [
                { title: '保险类型', dataIndex: 'insurance' },
                { title: '投保金额', dataIndex: 'money' },
                { title: '保险生效时间', dataIndex: 'createtime' },
                { title: '保险到期时间', dataIndex: 'endtime' },
                { title: '投保时间', dataIndex: 'time' },
                { title: '操作人', dataIndex: 'sname' },
                {
                    title: '操作',
                    width: 100,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            clothesColumns: [
                { title: '发放编号', dataIndex: 'id' },
                { title: '工服类型', dataIndex: 'clotype' },
                { title: '工服金额', dataIndex: 'money' },
                { title: '发放时间', dataIndex: 'time' },
                { title: '补助开始时间', dataIndex: 'createtime' },
                { title: '补助结束时间', dataIndex: 'endtime' },

                {
                    title: '操作',
                    width: 100,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            subsidyColumns: [
                { title: '工服补助月数', dataIndex: 'clotype' },
                { title: '工服金额', dataIndex: 'money' },
                { title: '补助金额', dataIndex: 'subsidy_money' },
                { title: '补助发放时间', dataIndex: 'subsidy_time' },
            ],
        };
    },
    mounted: function () {
        this.id = this.$route.query.id;
        this.getStaff();
        // this.getSkillid();
        this.getOperat();
        this.getInsure();
        this.getClotype();
        this.getClothes();
        this.getSubsidy();
        if (this.id) {
            this.getDetail();
        }
        if (isMobile()) {
            this.flag = true;
        } else {
            this.flag = false;
        }
    },

    methods: {
        submit() {
            let url = '/jieb/Worker/workeradd';
            if (this.id) {
                url = '/jieb/Worker/workeredit';
                this.data.id = this.id;
            }
            requestXml(url, "POST", (status, res) => {
                console.log(status, res);
                // if(status!='error'){
                //     this.$message.success('成功');
                // }
                // if(res){
                //     this.$message.info(res.msg);
                // }
            }, this.data)
        },
        // onPickTime(date, dateString){
        //     console.log(date);
        //     var time=new Date(dateString).getTime();
        //     this.data.createtime= Math.round(time/1000); 
        // },
        // 
        //获取工服补助记录
        getSubsidy() {
            requestXml("/jieb/Worker/workerdeta", "POST", (res) => {
                this.subsidyList = res.subsidy;
            }, { id: this.id })
        },
        showClothes() {
            this.clothesModal = true;
        },
        //获取工服发放记录
        getClothes() {
            requestXml("/jieb/Worker/clothesshow", "POST", (res) => {
                this.clothesList = res.list;
            }, { id: this.id })
        },
        setClothes() {
            if (!this.onClotype) {
                this.$message.info('请选择要发放的工服');
                return false;
            }
            let time = Math.round(new Date() / 1000);
            requestXml("/jieb/Worker/clothesadd", "POST", (res) => {
                console.log(res);
                this.onClotype = '';
                this.clothesModal = false;
                this.getClothes();
            }, { time: time, clotypeid: this.onClotype, workerid: this.id })
        },
        delClothes(id) {
            requestXml("/jieb/Worker/clothesdel", "POST", (res) => {
                console.log(res);
                this.getClothes();
            }, { id: id })
        },
        // 获取工服分类
        getClotype() {
            requestXml("/jieb/Worker/clotypeshow", "POST", (res) => {
                this.clotypeList = res.list;
            }, { "page": { "curpage": 1, "pagesize": 999 } })
        },

        showInsure() {
            this.insureModal = true;
        },
        // 选择开始时间
        onPickCTime(date, dateString) {
            console.log(date);
            var time = new Date(dateString).getTime();
            this.data.createtime = Math.round(time / 1000);
        },
        // 投保 开始时间
        onInsCTime(date, dateString) {
            console.log(date);
            var time = new Date(dateString).getTime();
            this.insData.createtime = Math.round(time / 1000);
        },
        // 投保 结束
        onInsETime(date, dateString) {
            console.log(date);
            var time = new Date(dateString).getTime();
            this.insData.endtime = Math.round(time / 1000);
        },
        insuranceChange() {
            this.insData.createtime = ''
            this.insData.endtime = '';
        },
        setInsure() {
            let data = this.insData;
            data.createtime= Math.round(new Date(data.createtime).getTime()/1000);
            data.endtime=Math.round(new Date(data.endtime).getTime()/1000);
            console.log(data);
            if (!data.insurance || !data.money) {
                this.$message.info('请确定信息填写完整');
                return false;
            }
            if (!data.createtime || !data.endtime && data.insurance != 1) {
                this.$message.info('请填写时间信息');
                return false;
            }
            let time = Math.round(new Date() / 1000);
            requestXml("/jieb/Worker/insureadd", "POST", (res) => {
                this.getInsure();
                console.log(res);
                if (res != 'error') {
                    this.insureModal = false;
                }
            }, { workerid: this.id, insurance: data.insurance, money: data.money, createtime: data.createtime, endtime: data.endtime, time: time })
        },
        //获取投保信息
        getInsure() {
            requestXml("/jieb/Worker/insureshow", "POST", (res) => {
                let insType = ['', '工伤保险', '商业保险', '社会保险'];
                for (let i = 0; i < res.list.length; i++) {
                    res.list[i].createtime = res.list[i]?.createtime;
                    res.list[i].endtime = res.list[i]?.endtime;
                    res.list[i].insurance = insType[Number(res.list[i].insurance)];
                }
                this.insureList = res.list;
                console.log(res);
            }, { id: this.id })
        },
        delInsure(id) {
            requestXml("/jieb/Worker/insuredel", "POST", (res) => {
                console.log(res);
                this.getInsure();
            }, { id: id })
        },

        // 获取工作点
        getOperat() {
            requestXml("/jieb/Operating/operatshow", "POST", (res) => {
                this.operatList = res.list;
            }, { "page": { "curpage": 1, "pagesize": 999999 } })
        },
        // 获取技能分类
        getSkillid() {
            requestXml("/jieb/Temporary/skillshow", "POST", (res) => {
                this.skillid = res.list;
                // console.log(this.skillid);
                // console.log(JSON.stringify( this.skillid));

                // console.log(this.skillid);
            }, { "page": { "curpage": 1, "pagesize": 999999 } })
        },
        getStaff() {
            requestXml("/scrm/Staff/getMinList", "POST", (res) => {
                this.staff = res;
            })
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Worker/workeredit", "GET", (res) => {
                this.data = Object.assign({}, res);
                this.createtime = getDateTime(res.createtime);
                this.data.age = getDateTime(res.age);
                this.data.endtime = getDateTime(res.endtime);
            }, { id: this.id })
        },
    }
};
</script>
<style scoped>
.action {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px;
    background: #dceeff;
    border: 1px solid #36a3ff;
    color: #36a3ff;
    border-radius: 5px;
}
.del {
    background: #ffdcdc;
    border-color: #ff4c4c;
    color: #ff4c4c;
}
.content .content_row {
    align-items: center;
    margin: 2rem 0;
}
.content_right {
    width: calc(100% - 120px);
    text-align: left;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #36a3ff;
    border-color: #36a3ff;
}
.content .content_row .info {
    display: inline-block;
    width: 15rem;
    text-align: right;
    padding-right: 1rem;
    color: #202020;
    font-weight: normal;
}
.content .content_row .fileLabel {
    line-height: 1.499;
    font-weight: 400;
    background-image: none;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    padding: 5px 15px;
    /* font-size: 14px; */
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
}
.content .content_row input[type='file'] {
    display: none;
}
/* 输入框 下拉框 */
.content .content_row input,
.content .content_row .ant-select {
    width: 100%;
    /* background-color: #F9F9F9; */
}
.content .content_row .ant-select-selection--multiple input {
    width: 0;
}
/* 日期 */
.content .content_row .ant-calendar-picker {
    width: 100% !important;
}
.content .content_row .ant-calendar-picker input {
    width: 100%;
}

.save {
    text-align: left;
}
.save .ant-btn {
    width: 100px;
    height: 40px;
    background: #36a3ff;
    border-radius: 4px;
    margin-left: 13rem;
}
.add_title {
    padding: 15px 0;
}
.add_inp {
    margin-bottom: 60px;
}
.action1 span {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 10px;
}
@media screen and (min-width: 750px) {
    .content .content_row {
        margin: 15px 0;
        font-size: 16px;
    }
    .content .content_row .info {
        width: 90px;
        padding-right: 10px;
    }
    .content .content_row .ant-calendar-picker {
        width: 400px !important;
    }
    .content .content_row .ant-calendar-picker input {
        width: 100% !important;
    }
    /* input ,selsct */
    .content .content_row input,
    .content .content_row .ant-select,
    .content .content_row textarea,
    .content .content_row .ant-input-affix-wrapper {
        width: 400px;
    }
    .save .ant-btn {
        margin-left: 90px;
    }
}
</style>
